<template>
	<v-container fluid class="pa-0">
        <v-row v-show="!loading">
            <top-nav :title="`Mensaje de ${contactForm.name}`"></top-nav>
            <v-col cols="12" class="pa-0">
                <v-card flat tile color="grey lighten-5" class="py-5">
                    <div class="text-h6 grey--text text--darken-3 px-4">{{ contactForm.name }}</div>
                    <v-card-subtitle class="pt-0">
                        <div>{{ contactForm.email }}</div>
                        <div>{{ (new Date(contactForm.dateCreated)).toLocaleDateString() }}</div>
                    </v-card-subtitle>
                    <v-card-text>{{ contactForm.message}}</v-card-text>
                    <v-card-actions v-if="contactForm.email" class="px-4">
                        <v-btn class="petu-orange-bg no-uppercase" depressed :href="`mailto:${contactForm.email}`" rounded dark block>Responder</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
	</v-container>
</template>
<script>
import TopNav from '@/components/admin/TopNav'
import { ContactForm } from '@/classes/contactForm'

export default {
	name		: 'AdminMessages',
    props:  { id: String },
	components	: { TopNav },
    data() {
        return {
            loading: true,
            contactForm: { name: null, email: null, message: null }
        }
    },
    async mounted() {
        if (!this.id) return
        let contactForm = new ContactForm()
        await contactForm.load(this.id)
        this.contactForm = contactForm.data
        this.loading = false
    }
}
</script>
